<template>
    <a href="">{{text}}</a>
</template>

<script>
export default {
    props: {
        text: String,
    }
}
</script>

<style scoped>
a {
  display: block;
  color: var(--font-color);
  padding: 16px;
  text-decoration: none;
}

@media screen and (max-width: 700px) {
    a {float: left;}
}

@media screen and (max-width: 400px) {
  a {
    text-align: center;
    float: none;
  }
}

</style>