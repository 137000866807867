import { createStore } from 'vuex'

export default createStore({
  state: {
    darkmode: true,
    chatmessages: [],
    current_api_link: 'https://chat-q-api.josuakoehler.de:448/bot?'
  },
  mutations: {
    set_darkmode (state, isEnabled) {
      if (isEnabled) {
        document.documentElement.setAttribute('data-theme', 'dark');
      } else {
        document.documentElement.setAttribute('data-theme', 'light');
      }
      state.darkmode = isEnabled
    },
    change_darkmode(state) {
      this.commit("set_darkmode", !state.darkmode)
    },
    add_messages(state, messages){
      console.log(messages)
        messages[0].forEach(message => {
          message.in = true
          message.has_image = "image" in message
          state.chatmessages.unshift(message) 
        });
        console.log(state.chatmessages)
    },
    add_message(state, message){
      state.chatmessages.unshift(message)
    }

  },
  actions: {
    perform_query(state, queryword){
      let query = this.state.current_api_link + "msg=" + queryword
      console.log(query)
      fetch(query)
        .then(response => response.json())
        .then(data => this.commit("add_messages", data))
        .catch(err => console.log(err))}
  },
  modules: {
  },
  getters: {
    get_schema: (state) => state.schema,
    get_chatmessages: (state) => state.chatmessages
  }
})