<template>
  <div id="sidebar">
    <div class="sidebarheader">
      <h1>chat-q</h1>
      <h2>Control Panel</h2>
      <small>0.0.1 prealpha</small>
    </div>

    <div class="sidebarmenuitemsconatainer">
      <SidebarMenuItem :text="button" v-for="button in buttons" :key="button" />
    </div>

    <div id="sidebar_footer">
      <div class="sidebarfooterbutton" @click="change_darkmode">
        <img
          src="./../../../public/icons/icons8-light-on-50.png"
          alt=""
          class="sidebarfooterbuttonimg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarMenuItem from "./SidebarMenuItem.vue";
import { mapMutations } from "vuex";

export default {
  components: {
    SidebarMenuItem,
  },
  data() {
    return {
      buttons: ["Chat", "Korpus Editor", "Statistiken"],
    };
  },
  methods: {
    ...mapMutations(["change_darkmode"]),
  },
};
</script>

<style scoped>
#sidebar {
  margin: 0;
  padding: 0;
  width: var(--sidebar-width);
  background-color: var(--light-bg-color);
  position: fixed;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

#sidebar_footer {
  margin-top: auto;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
#sidebar a:hover {
    background-color: var(--bg-color);
    color: var(--font-color);
    transition: color 0.3s, background-color 1s;
}

@media screen and (max-width: 700px) {
 #app{
     overflow: scroll;
 }
  #sidebar {
    width: 100%;
    flex-grow: 1;
    position: relative;
  }
  #sidebar a {
    float: left;
  }
  #sidebar {
      height: auto;
      overflow: visible;

  }
  .sidebarmenuitemsconatainer{
      flex-direction: row;
      justify-content: space-around;
      position: relative;
  }
}

@media screen and (max-width: 400px) {
  #sidebar a {
    text-align: center;
    float: none;
  }
}

.sidebarfooterbutton {
  background-color: var(--light-bg-color);
  height: 60px;
  width: 60px;
  margin: 16px;
  transition: 0.3s background-color;
  border-radius: 15px;
}
.sidebarfooterbutton:hover {
  background-color: var(--lighter-bg-color);
  height: 60px;
  width: 60px;
  margin: 16px;
  transition: 0.3s background-color;
}

.sidebarfooterbuttonimg {
  padding: 5px;
}
.sidebarheader {
  margin-top: 1em;
}
.sidebarmenuitemsconatainer{
    margin-top: 2em;
}
</style>